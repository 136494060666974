import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ReferenceEmpty = ({ model, path, value }) => {
  const { className, renderAs, item } = model;

  if (value && (!(value instanceof Array) || value.length)) return null;

  return (
    <Wrapper className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ReferenceEmpty;
