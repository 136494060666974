import { useEffect } from "react";

const StatSortByJaroWrinker = ({ model, view }) => {
  useEffect(() => {
    const onSearch = (search) => ({ ...search, statFields: model.fields });

    view.on("search", 400, onSearch);
    return () => {
      view.off("search", onSearch);
    };
  }, [model.fields, view]);

  return null;
};
export default StatSortByJaroWrinker;
