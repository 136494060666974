import { ExportStyle } from "nystem-components";

const TextExportRef = ({ model, view, label }) => {
  const { className, labelClass, id, text } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };

  const value = view.getValue(id);
  const add = model.addToPath ? `${model.addToPath}/` : "";
  const attach = value ? `/image/${add}${value}.jpeg` : "";

  return { ...ExportStyle({ className }), value: attach, attach: attach };
};

export default TextExportRef;
