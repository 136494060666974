import {
  Link,
  Wrapper,
  SessionLogout,
  ConnectionView,
  Image,
  Icon,
  SessionRole,
  UploadProgress,
  ElectronMoveWindow,
  ElectronAppButton,
  ElectronView,
  ViewButtonBack,
  ElectronUrlCopyPaste,
} from "nystem-components";
import React, { useState, useEffect } from "react";
import app from "nystem";

const MenuLink = ({ to, children, className, match }) => (
  <Link
    className={`block rounded-md px-2 hover:bg-orange-100 hover:text-black hover:shadow ${
      className || ""
    }`}
    to={to}
    match={match || to}
  >
    {children}
  </Link>
);
const DropDownLink = ({ children, className, ...props }) => {
  const classNames = `hover:text-black px-2 block rounded-md ${
    className || ""
  }`;

  return (
    <Link {...props} className={classNames}>
      {children}
    </Link>
  );
};

const PartMenuv2 = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = () => {
      if (open === true) setOpen(false);
    };
    setTimeout(() => {
      window.addEventListener("click", close);
    }, 0);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open]);

  return (
    <Wrapper className="fixed top-0 z-20 w-full bg-blue-100 pl-3 pr-0 shadow md:mx-0">
      <Wrapper
        renderAs="nav"
        className="flex items-center justify-center text-base text-gray-800"
      >
        <Wrapper className="relative w-0">
          {open ? (
            <Wrapper className="absolute left-0 mt-6 w-32 border border-gray-300 bg-gray-100 text-base text-gray-800 shadow-xl">
              <ElectronView>
                <ElectronUrlCopyPaste
                  text="Copy URL"
                  className="flex w-full items-center p-2 text-sm hover:shadow"
                  btnType="plain"
                />
                <ElectronUrlCopyPaste
                  text="Paste URL"
                  paste={true}
                  className="flex w-full items-center p-2 text-sm hover:shadow"
                  btnType="plain"
                />
              </ElectronView>{" "}
              <SessionRole userrole="logged-in">
                <MenuLink
                  className="py-2 pt-3"
                  to="/upload/list"
                  match="/upload/*"
                >
                  Upload
                </MenuLink>
              </SessionRole>
              <SessionRole userrole="config super user">
                <MenuLink
                  className="py-2 pt-3"
                  to="/training/navigate"
                  match="/training/*"
                >
                  Training
                </MenuLink>
                <MenuLink className="py-2 pt-3" to="/trainingresult/list">
                  Result
                </MenuLink>
                <MenuLink className="py-2 pt-3" to="/census">
                  Census
                </MenuLink>
              </SessionRole>
              <MenuLink className="py-2 pt-3" to="/help">
                Help
              </MenuLink>
              <SessionRole userrole="config super">
                <DropDownLink
                  to="/admin"
                  className="py-2 hover:bg-orange-100 hover:shadow"
                >
                  Admin
                </DropDownLink>
              </SessionRole>
              <SessionRole userrole="logged-in">
                <SessionLogout
                  type="button"
                  className="block cursor-pointer py-2 px-2  hover:bg-orange-100 hover:shadow"
                  to="/"
                >
                  {app().t("Log out")}
                </SessionLogout>
              </SessionRole>
            </Wrapper>
          ) : null}
        </Wrapper>
        <Icon
          icon="bars"
          className="mr-3 h-8 w-8 cursor-pointer px-1"
          onClick={() => setOpen(!open)}
        />
        <Link to="/" exact="true">
          <ConnectionView>
            <Image
              alt="Logo"
              className="text-red pr-2"
              width={40}
              height={30}
              src="/image/logo.svg"
            />
          </ConnectionView>
          <ConnectionView offline="true">
            <Image
              alt="Logo"
              className="text-red pr-2 grayscale"
              width={40}
              height={30}
              src="/image/logo.svg"
            />
          </ConnectionView>
        </Link>
        <ElectronMoveWindow className="flex flex-grow">
          <SessionRole userrole="logged-in">
            <MenuLink className="py-3" to="/upload/list" match="/upload/*">
              Upload
            </MenuLink>
          </SessionRole>
          <SessionRole userrole="config super user">
            <MenuLink
              className="py-3"
              to="/training/navigate"
              match="/training/*"
            >
              Training
            </MenuLink>
            <MenuLink className="py-3" to="/trainingresult/list">
              Result
            </MenuLink>
            <MenuLink className="py-3" to="/census">
              Census
            </MenuLink>
          </SessionRole>
          <MenuLink className="py-3" to="/help">
            Help
          </MenuLink>
          <Wrapper className="flex-grow" />
          <ElectronView invert="true">
            <SessionRole userrole="logged-in">
              <SessionLogout
                type="button"
                className="block cursor-pointer rounded-md py-3 px-2 hover:bg-orange-100 hover:shadow"
                to="/"
              >
                {app().t("Log out")}
              </SessionLogout>
            </SessionRole>
            <SessionRole userrole="logged-out">
              <MenuLink className="py-3" to="/login">
                {app().t("Log in")}
              </MenuLink>
            </SessionRole>
          </ElectronView>
          <ElectronView>
            <ViewButtonBack size="xs" className="my-auto ml-6" />
            <ViewButtonBack fwd="true" size="xs" className="my-auto" />
          </ElectronView>
        </ElectronMoveWindow>
        <UploadProgress className="mr-10 animate-bounce" />
        <ElectronView>
          <ElectronAppButton
            className="mx-1 h-8 w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
            button="minus"
          />
          <ElectronAppButton
            className="mx-1 h-8 w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
            buttonActive="edit-copy"
            button="rectangle"
          />
          <ElectronAppButton
            className="mx-1 h-8 w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
            button="close"
          />
        </ElectronView>
      </Wrapper>
    </Wrapper>
  );
};
export default PartMenuv2;
