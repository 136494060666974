import { ExportStyle } from "nystem-components";

const jaroWrinker = (s1 = "", s2 = "") => {
  let m = 0;

  // Exit early if either are empty.
  if (s1.length === 0 || s2.length === 0) return 0;

  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  // Exit early if they're an exact match.
  if (s1 === s2) return 1;

  const range = Math.floor(Math.max(s1.length, s2.length) / 2) - 1;
  const s1Matches = new Array(s1.length);
  const s2Matches = new Array(s2.length);

  for (let i = 0; i < s1.length; i++) {
    const low = i >= range ? i - range : 0;
    const high = i + range <= s2.length ? i + range : s2.length - 1;

    for (let j = low; j <= high; j++)
      if (s1Matches[i] !== true && s2Matches[j] !== true && s1[i] === s2[j]) {
        ++m;
        // eslint-disable-next-line no-multi-assign
        s1Matches[i] = s2Matches[j] = true;
        break;
      }
  }

  // Exit early if no matches were found.
  if (m === 0) return 0;

  // Count the transpositions.

  let k = 0;
  let nTrans = 0;

  for (let i = 0; i < s1.length; i++)
    if (s1Matches[i] === true) {
      let j;
      for (j = k; j < s2.length; j++) {
        if (s2Matches[j] === true) {
          k = j + 1;
          break;
        }
      }
      if (s1[i] !== s2[j]) ++nTrans;
    }

  let weight = (m / s1.length + m / s2.length + (m - nTrans / 2) / m) / 3;
  let l = 0;
  const p = 0.1;

  if (weight > 0.7) {
    while (s1[l] === s2[l] && l < 4) ++l;
    weight += l * p * (1 - weight);
  }

  return weight;
};

const multiply = (arr) =>
  arr.map((item) => item.diff).reduce((prev, curr) => prev * curr, 1);

const StatExport = ({ model, view, label }) => {
  const { className, labelClass, text } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };

  const fields = model.fields.map((item) => ({
    ...item,
    value: view.baseView.getValue(item.id),
  }));

  let diff = fields.map((item) => {
    let val = view.getValue(item.id);
    if (!val) return { ...item, diff: [] };

    val = val instanceof Array ? val : [val];

    const diff = Math.max(...val.map((val) => jaroWrinker(item.value, val)));
    return { ...item, diff };
  });

  if (diff.length) {
    // eslint-disable-next-line no-restricted-properties
    diff = Math.pow(multiply(diff), 1 / diff.length);
  } else diff = 0.0;

  return { ...ExportStyle({ className }), value: diff.toFixed(4) };
};

export default StatExport;
