import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const StyleCssClassByFieldAndParam = ({ model, path, view }) => {
  const insertVal = (val) =>
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "_language") val = app().settings.lang;
      else if (p1 === "id") val = view.id;
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        val = view.baseView.getValue(p1.replace("..", path));
      }
      if (val instanceof Array) val = val.join("|");
      return val || "";
    });

  const { item, classNameMatch, classNameNoMatch, match1, match2 } = model;
  const matchVal = insertVal(match1);
  const isMatch =
    matchVal &&
    (!model.exact
      ? new RegExp(matchVal).test(insertVal(match2))
      : matchVal === insertVal(match2));

  return (
    <Wrapper className={isMatch ? classNameMatch : classNameNoMatch}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default StyleCssClassByFieldAndParam;
