import { useEffect, useState } from "react";
import app from "nystem";
import { useHistory } from "react-router-dom";

const UseEvent = (event, data) => {
  const [result, setResult] = useState({});

  useEffect(() => {
    app()
      .connection.event("emit", { type: event })
      .then((result) => setResult(result));
  }, [data, event]);

  return result;
};

const MediaRedirectOnLowStorage = ({ model }) => {
  const history = useHistory();
  const data = UseEvent("storageInfo");

  useEffect(() => {
    const { to, disk, limit } = model;
    const atDisk = data.info?.find((item) => item.name === disk);
    if (!atDisk) return;

    if (parseInt(atDisk.usedP.replace("%", ""), 10) > limit)
      history.replace(to);
  }, [data.info, history, model]);
  return null;
};

export default MediaRedirectOnLowStorage;
