/* eslint-disable guard-for-in */
import React, { useState, useEffect } from "react";
import moment from "my-moment";
import { Wrapper, ExportStyle } from "nystem-components";

function attach(current, updates) {
  Object.keys(updates).forEach((key) => {
    current[key] = updates[key];
  });
}

const ExportExcel = ({ model, view }) => {
  const [text, setText] = useState(model.text);

  const { className, renderAs } = model;

  useEffect(() => {
    let rows = false;
    let workbook = false;
    let sheet = false;
    const {
      labelClass = [],
      rowClass = [],
      rowClassEven = [],
      rowClassOdd = [],
      addDate,
    } = model;

    const addRow = (items) => {
      const row = sheet.addRow();
      items.forEach((item, index) => {
        const cell = row.getCell(index + 1);
        let { value } = item;
        value = value instanceof Array ? value.join(", ") : value;
        attach(cell, { ...item, value });
      });
      return row;
    };

    const exportStart = async () => {
      const { creator, stickyLabel } = model;
      const { exceljs } = await import("../../client/exceljs");
      workbook = exceljs();

      workbook.creator = creator;
      workbook.lastModifiedBy = creator;
      workbook.created = new Date();
      workbook.modified = new Date();
      workbook.lastPrinted = new Date();

      const props = stickyLabel && {
        views: [{ state: "frozen", ySplit: 1 }],
      };
      sheet = workbook.addWorksheet(model.filename, props);

      rows = [];
    };
    view.on("exportStart", 100, exportStart);

    const exportLabels = ({ data }) => {
      attach(addRow(data), ExportStyle({ className: labelClass }));
    };
    view.on("exportLabels", exportLabels);

    const exportAddRow = async ({ data }) => {
      rows.push(await addRow((await Promise.all(data)).flat()));
    };
    view.on("exportAddRow", -10, exportAddRow);

    const exportFinish = async (query) => {
      if (query.aborted) return;

      const rowProps = (index) => {
        const even = index % 2 === 0;
        const className = [rowClass, even ? rowClassEven : rowClassOdd].flat();
        return ExportStyle({ className });
      };
      rows.forEach((row, index) => attach(row, rowProps(index)));

      sheet.columns.forEach((column) => {
        const lengths = column.values.map((v) => v.toString().length);
        const maxLength = Math.max(
          ...lengths.filter((v) => typeof v === "number")
        );
        column.width = maxLength + 2;
      });

      const data = await workbook.xlsx.writeBuffer();
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      let fileName = model.filename;
      if (addDate)
        fileName += ` ${moment()
          .format("YYYY-MM-DD HH:mm")
          .replace(":", ".")} `;
      fileName += ".xlsx";

      return { ...query, fileName, blob };
    };
    view.on("exportFinish", 100, exportFinish);
    const exportFinishDone = () => {
      setText(model.text);
    };
    view.on("exportFinish", -1000, exportFinishDone);

    return () => {
      view.off("exportStart", exportStart);
      view.off("exportLabels", exportLabels);
      view.off("exportAddRow", exportAddRow);
      view.off("exportFinish", exportFinish);
      view.on("exportFinish", exportFinishDone);
    };
  }, [model, view]);

  return (
    <Wrapper
      onClick={(event) => {
        if (text === model.text) {
          setText(model.textProgress);
          view.event("exportStart", { event, excel: true });
        } else {
          setText(model.text);
          view.event("exportFinish", { aborted: true });
        }
      }}
      className={className}
      renderAs={renderAs}
    >
      {text}
    </Wrapper>
  );
};

export default ExportExcel;
