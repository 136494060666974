import React, { useEffect, useState } from "react";
import { BooleanInput, RouterUseQueryStore } from "nystem-components";

const ReferenceGroupRef = ({ model, view, value, setValue }) => {
  const [queryValue, setQueryValue] = RouterUseQueryStore(model.saveId);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (value || checked) return;

    const setQueryVal = async ({ _id }) => {
      setQueryValue(_id);
    };
    view.on("save", -20, setQueryVal);
    return () => {
      view.off("save", setQueryVal);
    };
  }, [checked, setQueryValue, value, view]);

  useEffect(() => {
    if (!queryValue || queryValue === value) return;

    setValue(queryValue);
  }, [queryValue, setValue, value]);

  if (model.hidden || (!model.allowAlone && !queryValue && !checked))
    return null;

  return (
    <BooleanInput
      model={model}
      value={checked}
      setValue={() => {
        if (!checked && model.allowAlone && !queryValue) setChecked(true);
        else if (queryValue) {
          setChecked(queryValue);
          setQueryValue();
        } else {
          setChecked(false);
          if (checked !== true) setQueryValue(checked);
        }
      }}
    />
  );
};

export default ReferenceGroupRef;
