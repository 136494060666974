import { useEffect } from "react";
import app from "nystem";

const ElectronExport = ({ model = {}, view }) => {
  useEffect(() => {
    const { fieldType = [] } = model;
    let files = [];

    const exportAddRow = async ({ data }) => {
      const { path } = await view.event("electronFolder");

      let attachments = data
        .map(({ attach }) => attach)
        .filter((attach) => attach);

      if (!attachments.length) return;
      attachments = attachments.flat(Infinity).filter((item) => item);

      for await (let attach of attachments) {
        if (attach.startsWith("/")) attach = attach.substring(1);
        if (files.includes(attach)) continue;

        files.push(attach);

        await app().event("electronEvent", {
          event: "syncFile",
          path,
          file: attach,
          domain: `http${app().settings.secure ? "s" : ""}://${
            app().settings.domain
          }`,
        });
      }
    };
    if (fieldType.includes("exportAddRow"))
      view.on("exportAddRow", -10, exportAddRow);

    const exportAddAttachment = async ({ fileName, blob }) => {
      const { path } = await view.event("electronFolder");

      return app().event("electronEvent", {
        event: "syncFile",
        path,
        file: { data: await blob.arrayBuffer(), fileName },
        domain: `http${app().settings.secure ? "s" : ""}://${
          app().settings.domain
        }`,
      });
    };
    if (fieldType.includes("exportAddRow"))
      view.on("exportAddAttachment", exportAddAttachment);

    const exportFinish = async ({ blob, fileName, aborted }) => {
      if (aborted) return;

      const { path } = await view.event("electronFolder");

      app().event("electronEvent", {
        event: "syncFile",
        path,
        file: { data: await blob.arrayBuffer(), fileName },
        domain: `http${app().settings.secure ? "s" : ""}://${
          app().settings.domain
        }`,
        callback: true,
      });

      return {};
    };
    view.on("exportFinish", exportFinish);

    const exportFinishClear = async () => {
      files = [];
    };
    view.on("exportFinish", exportFinishClear);

    return () => {
      if (fieldType.includes("exportAddRow")) {
        view.off("exportAddRow", exportAddRow);
        view.off("exportAddAttachment", exportAddAttachment);
      }

      if (fieldType.includes("exportFinish"))
        view.off("exportFinish", exportFinish);

      view.off("exportFinish", exportFinishClear);
    };
  }, [model, view]);

  return null;
};

export default ElectronExport;
