import React from "react";
import { Wrapper } from "nystem-components";

const getSize = ({ x1, y1, x2, y2 }) => [x2 - x1, y2 - y1];

const StyleLine = ({ model, value, view }) => {
  if (!value) return null;
  const { className, areaField } = model;
  let area = view.baseView.getValue(areaField);

  // eslint-disable-next-line no-bitwise
  const reverse = (area.x1 > area.x2) ^ (area.y1 > area.y2);
  area = {
    x1: area.x1 < area.x2 ? area.x1 : area.x2,
    x2: area.x1 < area.x2 ? area.x2 : area.x1,
    y1: area.y1 < area.y2 ? area.y1 : area.y2,
    y2: area.y1 < area.y2 ? area.y2 : area.y1,
  };
  const [x, y] = getSize(area);
  const width = Math.sqrt(x * x + y * y);
  let angle = (Math.atan2(y, x) * 180) / Math.PI;
  let marginTop = -10;

  if (reverse) {
    angle = -angle;
    marginTop += y;
  }

  return (
    <Wrapper
      className={className}
      style={{
        marginTop: `${marginTop}px`,
        width: `${width}px`,
        transform: `rotate(${angle}deg)`,
        transformOrigin: "0 15px",
      }}
    >
      <Wrapper className="w-full border border-black" />
    </Wrapper>
  );
};
export default StyleLine;
