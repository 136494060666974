import { useEffect } from "react";
import { ExportView } from "nystem-components";

const getValue = (value) => (path) =>
  path ? path.split(".").reduce((val, key) => val && val[key], value) : value;

const ExportLineMask = ({ model, view }) => {
  useEffect(() => {
    let labels = false;
    let files = false;
    const contentTypeView = view;

    const exportAddRow = async ({ data, ...rest }) => {
      if (labels && !files) return;

      const view = { getValue: getValue(data) };

      if (!labels) {
        const items = await Promise.all(
          model.item.map((model) => ExportView({ view, model, label: true }))
        );
        labels = items.flat().map(({ value }) => value);

        files = {};
      }

      const items = model.item.map((model) => ExportView({ view, model }));

      const mask = (await Promise.all(items)).flat();
      const value = mask.map(({ value }) => value);

      const result = labels.reduce((result, key, index) => {
        result[key] = value[index];
        return result;
      }, {});

      if (!result.training) {
        files = false;
        return;
      }

      const { area, refImage, images, option } = result;
      const { x1, x2, y2, y1 } = area;
      const width = x2 - x1;
      const height = y2 - y1;
      const type =
        Math.abs(width) < Math.abs(height) ? "vertical" : "horizontal";

      if (!files[`${refImage}_${type}_${option}`]) {
        const fImage = images.find(({ id }) => id === refImage);

        files[`${refImage}_${type}_${option}`] = { fImage, lines: [] };
        contentTypeView.event("exportCount", { count: 1 });
      }
      files[`${refImage}_${type}_${option}`].lines.push({ x1, x2, y2, y1 });

      return { data, ...rest, exclude: true };
    };
    view.on("exportAddRow", 100, exportAddRow);

    const exportFilterAttachment = ({ data, exclude, ...rest }) => ({
      data: exclude
        ? data &&
          data.filter(
            (item) =>
              item.attach && !item.attach[0].startsWith("/image/training/")
          )
        : data,
      ...rest,
    });

    view.on("exportAddRow", -2, exportFilterAttachment);

    const exportFinish = async ({ aborted }) => {
      if (!files || aborted) {
        labels = false;
        files = false;
        return;
      }

      for (const item of Object.entries(files)) {
        const [fileName, { fImage, lines }] = item;
        await view.event("exportCount", { count: -1 });

        const mask = document.createElement("canvas");
        mask.width = fImage.width;
        mask.height = fImage.height;

        lines.forEach(({ x1, x2, y2, y1 }) => {
          const ctx = mask.getContext("2d");
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.lineWidth = 10;
          ctx.strokeStyle = "#FFFFFF";
          ctx.stroke();
        });

        await view.event("exportAddAttachment", {
          blob: await new Promise((resolve) =>
            mask.toBlob(resolve, "image/jpeg", 0.95)
          ),
          fileName: `image/upload/${fileName}.jpeg`,
        });
      }
    };
    view.on("exportFinish", 100, exportFinish);

    return () => {
      view.off("exportAddRow", exportFilterAttachment);
      view.off("exportAddRow", exportAddRow);
      view.off("exportFinish", exportFinish);
    };
  }, [model, view]);

  return null;
};

export default ExportLineMask;
