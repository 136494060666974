import React, { useRef, useEffect, useState } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const StyleTooltip = ({ model, path, view }) => {
  const { className, item } = model;
  const [zoom, setZoom] = useState(100);
  const [style, setStyle] = useState({
    marginTop: `-200px`,
    visibility: "hidden",
  });

  const tooltip = useRef();
  useEffect(() => {
    const { height } = tooltip.current.getBoundingClientRect();

    setStyle({
      marginTop: `-${(height * 100) / zoom + 10}px`,
      visibility: "visible",
    });
  }, [zoom]);

  useEffect(() => {
    if (!view.baseView?.baseView) return;
    const zoomEvent = ({ value }) => {
      setZoom(value);
    };

    view.baseView.baseView
      .event("zoomSelect")
      .then(({ value }) => setZoom(value));

    view.baseView.baseView.on("zoomSelect", zoomEvent);
    return () => {
      view.baseView.baseView.off("zoomSelect", zoomEvent);
    };
  }, [view]);

  return (
    <>
      <Wrapper className={"absolute"}>
        <Wrapper
          ref={tooltip}
          className={["relative", className]}
          style={style}
        >
          <ContentTypeRender path={path} items={item} />
        </Wrapper>
      </Wrapper>
    </>
  );
};

export default StyleTooltip;
