import { ExportStyle } from "nystem-components";

const fields = ["x1", "y1", "x2", "y2"];

const AreaExport = ({ model, view, label }) => {
  const { className, labelClass, id, text } = model;

  if (label)
    return {
      ...ExportStyle({ className: labelClass }),
      value: text || fields.join(","),
    };

  let value = view.getValue(id);
  if (model.comma && value)
    value = fields.map((field) => value[field]).join(",");

  return { ...ExportStyle({ className }), value };
};

export default AreaExport;
