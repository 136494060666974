import { Link, SessionRole, Inserter, Wrapper, Icon } from "nystem-components";
import React from "react";

const NavbarLink = ({ children, type, icon, ...props }) => {
  const classNames =
    "block px-5 py-2 hover:bg-orange-100 hover:shadow flex items-center";

  if (type)
    props = { ...props, to: `/admin/${type}/list`, match: `/admin/${type}/*` };

  return (
    <Link {...props} className={`${classNames} ${props.className || ""}`}>
      <Icon icon={icon} className="mr-3 h-4 w-4" />
      {children}
    </Link>
  );
};

const PartAdminv2 = () => {
  return (
    <SessionRole userrole="logged-in">
      <Inserter match="/admin*" className="admin m-auto max-w-6xl p-1 sm:flex">
        <Wrapper className="-mt-6 rounded-br-lg bg-gray-100 text-black shadow sm:mt-0 sm:w-40 sm:pb-2">
          <NavbarLink
            icon="gear"
            to="/admin"
            className="mt-6 px-3 text-xl font-semibold"
          >
            Admin
          </NavbarLink>
          <NavbarLink icon="user" type="user">
            User
          </NavbarLink>
          <NavbarLink icon="circle-info" type="page">
            Page
          </NavbarLink>
          <NavbarLink icon="circle-info" type="district">
            District
          </NavbarLink>
          <NavbarLink icon="circle-info" type="municipality">
            Municipality
          </NavbarLink>
          <NavbarLink icon="circle-info" type="schooldistrict">
            School district
          </NavbarLink>
          <NavbarLink icon="circle-info" type="year">
            Year
          </NavbarLink>
          <NavbarLink icon="circle-question" type="training">
            Training
          </NavbarLink>
          <NavbarLink icon="circle-question" type="showstatdata">
            Stat data
          </NavbarLink>
        </Wrapper>
        <Wrapper className="flex-grow px-8 pt-2 pb-8">
          <Inserter match="/admin" source="/page/list" />
          <Inserter match="/admin/page*" source="/page*" />
          <Inserter match="/admin/user*" source="/user*" />
          <Inserter match="/admin/district*" source="/district*" />
          <Inserter match="/admin/municipality*" source="/municipality*" />
          <Inserter match="/admin/schooldistrict*" source="/schooldistrict*" />
          <Inserter match="/admin/year*" source="/year*" />
          <Inserter match="/admin/training*" source="/training*" />
          <Inserter match="/admin/showstatdata*" source="/showstatdata*" />
          <Inserter match="/admin/menu*" source="/menu*" />
        </Wrapper>
      </Inserter>
    </SessionRole>
  );
};

export default PartAdminv2;
