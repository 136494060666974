import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const MediaCheckOrientationStatus = ({ model, value }) => {
  const { item } = model;
  value = value || [];

  if (!value.find((item) => !item.orientation)) return null;

  return <ContentTypeRender items={item} />;
};
export default MediaCheckOrientationStatus;
