import React from "react";
import app from "nystem";
import { Button, Wrapper, RouterUseQueryStore } from "nystem-components";

const ViewButtonQueryParam = ({ model, view, path }) => {
  const [value, setValue] = RouterUseQueryStore(model.field, "", true);
  if (value === "") return null;

  const insertVal = (val) =>
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "_language") val = app().settings.lang;
      else if (p1 === "id") val = view.id;
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        val = view.baseView.getValue(p1.replace("..", path));
      }
      if (val instanceof Array) val = val.join("|");
      return val || "";
    });

  return (
    <Wrapper className={model.className}>
      <Button
        type={model.btnType}
        size={model.size}
        onClick={() => setValue(insertVal(model.fetch || ""))}
      >
        {app().t(model.text || "...")}
      </Button>
    </Wrapper>
  );
};
export default ViewButtonQueryParam;
