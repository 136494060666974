import { useEffect } from "react";
import app from "nystem";

const pick = (fields, value) =>
  fields
    ? fields.reduce(
        (prev, [id, to]) => (value[id] ? { ...prev, [to]: value[id] } : prev),
        {}
      )
    : value;

const ViewButtonEventEmitOnLoad = ({ model, view }) => {
  useEffect(() => {
    const emitterByType = {
      connection: {
        event: (type, data) => app().connection.emit({ type, ...data }),
      },
      view: view,
      baseView: view.baseView,
      baseViewBaseView: view.baseView?.baseView,
      baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
      app: app(),
    };
    const emitter = emitterByType[model.eventType || "connection"];

    emitter.event(model.event, {
      event: model.subEvent,
      value: pick(model.fields, view.value),
      contentType: view.contentType,
    });
    const setOnEmpty = ({ value, ...rest }) => {
      if (!value) return { ...rest, value: pick(model.fields, view.value) };
    };
    if (!model.setOnEmpty) return;

    emitter.on(model.event, 100, setOnEmpty);
    return () => {
      emitter.off(model.event, setOnEmpty);
    };
  }, [model, view]);

  return null;
};
export default ViewButtonEventEmitOnLoad;
