import { useEffect } from "react";
import app from "nystem";
import waitInLine from "../../client/waitInLine";

waitInLine.init("imageMod");

const rotate = async (image, orientation = 1) => {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  let x = 0;
  let y = 0;
  ctx.save();
  if (orientation === 2) {
    x = -canvas.width;
    ctx.scale(-1, 1);
  } else if (orientation === 3) {
    x = -canvas.width;
    y = -canvas.height;
    ctx.scale(-1, -1);
  } else if (orientation === 4) {
    y = -canvas.height;
    ctx.scale(1, -1);
  } else if (orientation === 5) {
    canvas.width = image.height;
    canvas.height = image.width;
    ctx.translate(canvas.width, canvas.height / canvas.width);
    ctx.rotate(Math.PI / 2);
    y = -canvas.width;
    ctx.scale(1, -1);
  } else if (orientation === 6) {
    canvas.width = image.height;
    canvas.height = image.width;
    ctx.translate(canvas.width, canvas.height / canvas.width);
    ctx.rotate(Math.PI / 2);
  } else if (orientation === 7) {
    canvas.width = image.height;
    canvas.height = image.width;
    ctx.translate(canvas.width, canvas.height / canvas.width);
    ctx.rotate(Math.PI / 2);
    x = -canvas.height;
    ctx.scale(-1, 1);
  } else if (orientation === 8) {
    canvas.width = image.height;
    canvas.height = image.width;
    ctx.translate(canvas.width, canvas.height / canvas.width);
    ctx.rotate(Math.PI / 2);
    x = -canvas.height;
    y = -canvas.width;
    ctx.scale(-1, -1);
  }

  ctx.drawImage(image, x, y);
  ctx.restore();

  return {
    width: canvas.width,
    height: canvas.height,
    img: await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", 0.95)
    ),
  };
};

const loadImage = ({ arr, blob, url }) =>
  new Promise((resolve) => {
    const image = new Image();

    image.src =
      url ||
      window.URL.createObjectURL(
        blob || new Blob([arr], { type: "image/jpeg" })
      );
    image.onload = () => resolve(image);
  });

const split = async (img) => {
  const getImage = ({ img, top = 0, bottom, left = 0, right }) => {
    if (bottom === undefined) bottom = img.height;
    if (right === undefined) right = img.width;

    const canvas = document.createElement("canvas");
    canvas.width = right - left;
    canvas.height = bottom - top;

    canvas.getContext("2d").drawImage(img, -left, -top);

    return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 0.95));
  };

  return {
    left: await getImage({ img, right: img.width / 2 }),
    right: await getImage({ img, left: img.width / 2 }),
  };
};

const MediaImageMods = () => {
  useEffect(() => {
    const imageModRotate = (query) =>
      waitInLine.waitInLine("imageMod", async () => {
        const img = await loadImage(query);

        return {
          ...query,
          ...(await rotate(img, query.orientation)),
        };
      });

    const imageModSplit = async (query) =>
      waitInLine.waitInLine("imageMod", async () => {
        const img = await loadImage(query);

        return { ...query, ...(await split(img)) };
      });

    app().on("imageModRotate", imageModRotate);
    app().on("imageModSplit", imageModSplit);
    return () => {
      app().off("imageModRotate", imageModRotate);
      app().off("imageModSplit", imageModSplit);
    };
  }, []);

  return null;
};
export default MediaImageMods;

/*
const converter = {
  1: { 0: 1, 90: 5, 180: 3, 270: 8 },
  5: { 0: 5, 90: 3, 180: 8, 270: 1 },
  3: { 0: 3, 90: 8, 180: 1, 270: 5 },
  7: { 0: 8, 90: 1, 180: 5, 270: 3 },
};
The 8 EXIF orientation values are numbered 1 to 8.

1 = 0 degrees: the correct orientation, no adjustment is required.
2 = 0 degrees, mirrored: image has been flipped back-to-front.
3 = 180 degrees: image is upside down.
4 = 180 degrees, mirrored: image has been flipped back-to-front and is upside down.
5 = 90 degrees: image has been flipped back-to-front and is on its side.
6 = 90 degrees, mirrored: image is on its side.
7 = 270 degrees: image has been flipped back-to-front and is on its far side.
8 = 270 degrees, mirrored: image is on its far side.
*/
