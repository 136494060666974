import colors from "./colors.json";

const fontSizes = {
  sm: 12,
  base: 16,
  lg: 20,
  xl: 26,
  "2xl": 32,
  "3xl": 45,
};

const dims = {
  4: 20,
  8: 40,
  12: 60,
  16: 80,
};

const borders = {
  t: "top",
  l: "left",
  b: "bottom",
  r: "right",
};

const getColor = (type, className) => {
  const base = `${type}-(${Object.keys(colors).join("|")})(-([1-9]00?))?`;
  const [, color, , val] = className.match(new RegExp(base, "im")) || [];
  if (!color) return false;
  const code = val ? colors[color][val] : colors[color];
  return code.replace("#", "");
};

const getStyle = (className = "") => {
  if (className === "font-bold")
    return {
      font: {
        bold: true,
      },
    };

  let code = getColor("bg", className);
  if (code)
    return {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: `FF${code.toUpperCase()}` },
      },
    };

  code = getColor("text", className);
  if (code)
    return {
      font: {
        color: { argb: `FF${code.toUpperCase()}` },
      },
    };

  let base = `text-(${Object.keys(fontSizes).join("|")})`;
  let [, size] = className.match(new RegExp(base, "im")) || [];
  if (size)
    return {
      font: {
        size: fontSizes[size],
      },
    };

  let dir = 0;

  base = `(w|h)-(${Object.keys(dims).join("|")})`;
  [, dir, size] = className.match(new RegExp(base, "im")) || [];
  if (size)
    return {
      [dir === "h" ? "height" : "width"]: dims[size],
    };

  base = `border-(${Object.keys(borders).join("|")})`;
  [, dir] = className.match(new RegExp(base, "im")) || [];
  if (dir)
    return {
      border: {
        [borders[dir]]: { style: "thin" },
      },
    };

  base = `align-(top|middle|bottom)`;
  [, dir] = className.match(new RegExp(base, "im")) || [];
  if (dir) return { alignment: { vertical: dir } };

  base = `text-(left|center|right)`;
  [, dir] = className.match(new RegExp(base, "im")) || [];
  if (dir) return { alignment: { horizontal: dir } };

  base = `m-(1|2|3)`;
  [, dir] = className.match(new RegExp(base, "im")) || [];
  if (dir) return { alignment: { indent: parseInt(dir, 10) } };

  return {};
};

function merge(current, updates) {
  for (const key of Object.keys(updates))
    if (!current.hasOwnProperty(key) || typeof updates[key] !== "object")
      current[key] = updates[key];
    else if (current[key] instanceof Array && updates[key] instanceof Array)
      current[key] = current[key].concat(updates[key]);
    else merge(current[key], updates[key]);

  return current;
}

const ExportStyle = ({ className }) => {
  if (!className) return {};
  className = className instanceof Array ? className : [className];
  return className.reduce((res, curr) => merge(res, getStyle(curr)), {});
};

export default ExportStyle;
