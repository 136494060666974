import React, { useState, useRef, useEffect, useCallback } from "react";
import { InputWrapper, Wrapper } from "nystem-components";
import moment from "my-moment";

const parts = [
  {
    id: "19year",
    placeHolder: "Year",
    parse: "YY",
    maxLength: 2,
    inputClass: "w-8 border rounded p-1 mx-1",
    base: 1900,
    pos: 0,
  },
  {
    id: "20year",
    placeHolder: "Year",
    parse: "YY",
    maxLength: 2,
    inputClass: "w-8 border rounded p-1",
    base: 2000,
    pos: 0,
  },
  {
    id: "fullyear",
    placeHolder: "Full year",
    parse: "YYYY",
    maxLength: 4,
    inputClass: "w-16 border rounded p-1 mx-1",
    pos: 0,
  },
  {
    id: "month",
    placeHolder: "Month",
    parse: "MM",
    maxLength: 2,
    inputClass: "w-8 border rounded p-1 mx-1",
    pos: 1,
  },
  {
    id: "day",
    placeHolder: "Day",
    parse: "DD",
    maxLength: 2,
    inputClass: "w-8 border rounded p-1 mx-1",
    pos: 2,
  },
];

const FieldInput = ({ model, value, onChange }) => {
  const [focusVal, setFocusVal] = useState(undefined);

  const {
    parse,
    placeHolder,
    maxLength,
    inputClass,
    pos,
    base,
    focus,
    format,
  } = model;
  const val = value ? moment(value).format(parse) : "";
  const ref = useRef();
  useEffect(() => {
    if (focus && ref.current) ref.current.focus();
  }, [focus]);

  return (
    <>
      <Wrapper>{format.length === 4 && base && base / 100}</Wrapper>
      <input
        ref={ref}
        placeholder={placeHolder}
        className={inputClass}
        value={focusVal !== undefined ? focusVal : val}
        maxLength={maxLength}
        type="text"
        onFocus={() => {
          ref.current.select();
          setFocusVal(val);
        }}
        onChange={(e) => {
          setFocusVal(e.target.value);
          if (e.target.value !== "") onChange(pos, e.target.value);
        }}
        onBlur={() => {
          onChange(pos, focusVal);
          setFocusVal(undefined);
        }}
      />
    </>
  );
};

const ParsePart = ({ model, value }) =>
  value ? moment(value).format(model.format) : "";

const getParts = (model) => {
  const { fields = [], dateFormat = "YYYY-MM-DD" } = model;
  let { focus } = model;
  const editable = parts
    .filter((item) => fields.includes(item.id))
    .reduce((result, curr) => {
      result[curr.parse] = curr;
      return result;
    }, {});

  if (!editable.YY) editable.YY = editable.YYYY;
  if (!editable.YYYY) editable.YYYY = editable.YY;
  if (!editable.M) editable.M = editable.MM;
  if (!editable.D) editable.D = editable.DD;

  const part = [];
  return dateFormat
    .replace(/YYYY|YY|MM?|DD?/gi, (format) => {
      part.push(
        editable[format]
          ? {
              model: { ...editable[format], focus, format },
              Component: FieldInput,
            }
          : {
              model: { format },
              Component: ParsePart,
            }
      );
      if (editable[format]) focus = false;
      return "||";
    })
    .split("||")
    .reduce((result, curr, index) => {
      result.push({ text: curr }, part[index]);
      return result;
    }, [])
    .filter((item) => item);
};

const DateFieldInput = ({ view, model, setValue, value }) => {
  const { yearFrom } = model;
  const fields = getParts(model);
  const [year, setYear] = useState(model.baseYear);

  const chDate = useCallback(
    (pos, val) => {
      if (!val && value) {
        setValue(undefined);
        return;
      }

      let valParts = fields.filter(({ model = {} }) => model.placeHolder);
      valParts = [
        valParts.find(
          ({ model }) => model.parse === "YY" || model.parse === "YYYY"
        ) || { model: { parse: "YY" } },
        valParts.find(({ model }) => model.parse === "MM"),
        valParts.find(({ model }) => model.parse === "DD"),
      ].filter((item) => item);
      valParts = valParts.map(({ model }) =>
        value ? parseInt(moment(value).format(model.parse), 10) : 1
      );

      valParts[pos] = parseInt(val, 10);
      valParts[1] = valParts[1] || 1;
      valParts[2] = valParts[2] || 1;
      valParts[1] -= 1;
      valParts[0] += fields[pos].base || 0;
      if (year) valParts[0] = year;

      const newVal = model.utc
        ? Date.UTC(...valParts)
        : new Date(...valParts).getTime();

      if (newVal !== value) setValue(newVal);
    },
    [model.utc, setValue, value, fields, year]
  );

  useEffect(() => {
    if (!yearFrom || !yearFrom.length) return;
    const [{ id }] = yearFrom;
    const val = view.getValue(id);

    if (val) setYear(parseInt(moment(val).format("YYYY"), 10));

    const onChange = ({ path, value }) => {
      if (id !== path) return;
      setYear(parseInt(moment(value).format("YYYY"), 10));
    };

    view.on("change", 2000, onChange);
    return () => {
      view.off("change", onChange);
    };
  }, [view, yearFrom]);

  useEffect(() => {
    if (!value || !year) return;
    if (parseInt(moment(value).format("YYYY"), 10) !== year)
      setTimeout(() => chDate(0, year), 0);
  }, [chDate, value, year]);

  return (
    <InputWrapper
      model={{
        ...model,
        classNameInput: "relative flex-grow flex",
      }}
    >
      <Wrapper className="flex items-center">
        {fields.map(({ model, text, Component }, index) =>
          text !== undefined ? (
            text === " " ? (
              <Wrapper key={index}>&nbsp;</Wrapper>
            ) : (
              <Wrapper key={index}>{text}</Wrapper>
            )
          ) : (
            <Component
              key={index}
              model={model}
              value={value}
              onChange={chDate}
            />
          )
        )}
      </Wrapper>
    </InputWrapper>
  );
};
export default DateFieldInput;
