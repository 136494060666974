import { useEffect } from "react";
import moment from "my-moment";

const addScript = (src) =>
  new Promise((resolve) => {
    const script = [...document.head.children].find(
      (child) => child.getAttribute("src") === src
    );

    if (script) {
      if (script.getAttribute("data-loaded")) resolve();
      else script.addEventListener("load", resolve);

      return;
    }

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("src", src);
    scriptEl.addEventListener("load", () => {
      scriptEl.setAttribute("data-loaded", true);
      resolve();
    });
    document.head.appendChild(scriptEl);
  });

const ExportZip = ({ model, view }) => {
  useEffect(() => {
    let zip = false;
    let zipWriter = false;
    let attached = [];

    const exportAddRow = async ({ data }) => {
      let attachments = data
        .map(({ attach }) => attach)
        .filter((attach) => attach);

      if (!attachments.length) return;
      attachments = attachments.flat(Infinity).filter((item) => item);

      if (!zipWriter) {
        await addScript("/zipjs/zip-full.min.js");
        if (!window.zip) return;

        ({ zip } = window);

        zipWriter = new zip.ZipWriter(new zip.BlobWriter("application/zip"));
      }

      for await (let attach of attachments) {
        if (attached.includes(attach)) continue;
        attached.push(attach);

        const response = await fetch(attach);

        if (response.status === 200) {
          const fileBlob = await response.blob();
          if (attach.startsWith("/")) attach = attach.substring(1);

          await zipWriter.add(attach, new zip.BlobReader(fileBlob));
        }
      }
    };
    view.on("exportAddRow", -10, exportAddRow);

    const exportAddAttachment = ({ fileName, blob }) =>
      zipWriter.add(fileName, new zip.BlobReader(blob));
    view.on("exportAddAttachment", exportAddAttachment);

    const exportFinish = async ({ blob, fileName, aborted }) => {
      attached = [];
      if (!zipWriter || aborted) return;

      await zipWriter.add(fileName, new zip.BlobReader(blob));
      const dataBlob = await zipWriter.close();
      zipWriter = false;

      fileName = model.filename || "export";
      if (model.addDate)
        fileName += ` ${moment()
          .format("YYYY-MM-DD HH:mm")
          .replace(":", ".")} `;
      fileName += ".zip";

      return { blob: dataBlob, fileName };
    };
    view.on("exportFinish", exportFinish);

    return () => {
      view.off("exportAddRow", exportAddRow);
      view.off("exportFinish", exportFinish);
      view.off("exportAddAttachment", exportAddAttachment);
    };
  }, [model, view]);

  return null;
};

export default ExportZip;
