import React, { useEffect, useState } from "react";
import { Wrapper } from "nystem-components";

const StatValidateData = ({ model, view, value }) => {
  const [error, setError] = useState(false);
  const { className, absence, absenceMonth } = model;

  useEffect(() => {
    const validate = ({ errors = [] }) => {
      console.log({ errors });
      const { KH, KAugust, KSeptember, KOktober } = value;

      if (KH >= 0 && KH < KAugust + KSeptember + KOktober) {
        setError(absence);
        return { errors: [...errors, absence] };
      }
      if (KAugust > 27 || KSeptember > 27 || KOktober > 27) {
        setError(absenceMonth);
        return { errors: [...errors, absenceMonth] };
      }

      setError(false);
    };

    view.on("validate", validate);
    return () => {
      view.off("validate", validate);
    };
  }, [absence, absenceMonth, value, view]);

  return error ? <Wrapper className={className}>{error}</Wrapper> : null;
};
export default StatValidateData;
