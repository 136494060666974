import React from "react";
import { InputWrapper, Wrapper, IntInput } from "nystem-components";

const TextFraction = ({ view, model, setValue, value = "" }) => {
  const [rest = "", second = ""] = value.split("/");
  const [number = "", first = ""] = model.number
    ? rest.split(" ")
    : [undefined, rest];

  return (
    <InputWrapper model={model}>
      <Wrapper className="flex items-center">
        {model.number && (
          <IntInput
            model={{ ...model, nolabel: true, text: "Number" }}
            view={view}
            value={number}
            setValue={(val) => {
              setValue(`${val} ${first}/${second}`);
            }}
          />
        )}
        <IntInput
          model={{ ...model, nolabel: true, focus: false, text: "Numerator" }}
          view={view}
          value={first}
          setValue={(val) => {
            setValue(
              model.number ? `${number} ${val}/${second}` : `${val}/${second}`
            );
          }}
        />
        /
        <IntInput
          model={{ ...model, nolabel: true, focus: false, text: "Denominator" }}
          view={view}
          value={second}
          setValue={(val) => {
            setValue(
              model.number ? `${number} ${first}/${val}` : `${first}/${val}`
            );
          }}
        />
      </Wrapper>
    </InputWrapper>
  );
};
export default TextFraction;
