import React, { useEffect } from "react";
import { Wrapper, TextLowercase, ContentTypeView } from "nystem-components";

const TextSuggest = ({ model, view, focus, setValue, value, path }, ref) => {
  useEffect(() => {
    const { id: modelId, field } = model;

    const add = ({ model, value, path: atPath }) => {
      if (path !== atPath || modelId !== model.id) return;

      setValue(value[field]);
    };

    view.on("referenceAdd", add);
    return () => {
      view.off("referenceAdd", add);
    };
  }, [model, path, setValue, view]);

  return (
    <Wrapper>
      <TextLowercase
        ref={ref}
        model={model}
        view={view}
        focus={focus}
        value={value}
        setValue={setValue}
      />
      <Wrapper className="flex px-3 -mt-2 mb-4">
        <Wrapper className="mr-2 sm:w-40 w-1/4 text-right align-top flex-shrink-0 text-sm">
          Suggestions
        </Wrapper>
        <ContentTypeView
          contentType={model.contentType}
          baseView={view}
          format={model.view}
          onReference={(item) => view.event(item.event, { ...item, model })}
          value={{ at: value }}
          className="flex-grow"
        />
      </Wrapper>
    </Wrapper>
  );
};
export default React.forwardRef(TextSuggest);
