import { useEffect } from "react";
import app from "nystem";

const SelectCorrectionsUpload = ({ model, view, value }) => {
  value = value || [];

  useEffect(() => {
    if (!value.includes("uprighter")) return;

    const uprighter = async (query) => {
      if (
        query.contentType !== view.contentType ||
        query.modelId !== model.imageId ||
        !query.data ||
        query.type !== "image"
      )
        return;

      const { img } = await app().event("imageModRotate", {
        orientation: query.pos % 2 === 0 ? 8 : 6,
        arr: query.data,
      });
      console.log(query);
      query.data = await img.arrayBuffer();
    };

    app().on("fileUpload", 935, uprighter);
    return () => {
      app().off("fileUpload", uprighter);
    };
  }, [model.id, model.imageId, value, view.contentType]);

  useEffect(() => {
    if (!value.includes("imagesplitter")) return;

    const imageModSplit = async (query) => {
      if (
        query.contentType !== view.contentType ||
        query.modelId !== model.imageId ||
        !query.data ||
        query.type !== "image" ||
        query.splitted
      )
        return;

      const { left, right } = await app().event("imageModSplit", {
        orientation: query.pos % 2 === 0 ? 8 : 6,
        arr: query.data,
      });

      query.pos *= 2;
      query.count *= 2;
      query.splitted = true;

      app().event("fileUpload", {
        ...query,
        id: app().uuid(),
        pos: query.pos + 1,
        name: `${query.name}.2`,
        data: await right.arrayBuffer(),
      });
      query.data = await left.arrayBuffer();
    };

    app().on("fileUpload", 919, imageModSplit);
    return () => {
      app().off("fileUpload", imageModSplit);
    };
  }, [model.id, model.imageId, value, view.contentType]);

  useEffect(() => {
    if (!value.includes("firstImageLast")) return;

    const fileUploadMediaSave = async (query) => {
      if (!query.media.length) return;

      const image = query.media.pop();
      query.media.unshift(image);
    };

    app().on("fileUploadMediaSave", fileUploadMediaSave);
    return () => {
      app().off("fileUploadMediaSave", fileUploadMediaSave);
    };
  }, [model.imageId, value, view.contentType]);

  return null;
};
export default SelectCorrectionsUpload;
