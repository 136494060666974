/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from "react";
import { ContentTypeRender, InputWrapper, Wrapper } from "nystem-components";

const StylePanAndZoom = ({ model, view }) => {
  const { item } = model;
  const pointRef = useRef();
  const [pos, setPos] = useState(false);
  const [zoom, setZoom] = useState(50);

  useEffect(() => {
    if (!pos) return;
    let lastPos = pos;

    const mouseMove = (event) => {
      const { pageX, pageY } = event;

      pointRef.current.scrollLeft += lastPos.pageX - pageX;
      pointRef.current.scrollTop += lastPos.pageY - pageY;
      lastPos = { pageX, pageY };
    };

    document.addEventListener("mousemove", mouseMove);

    const mouseUp = () => {
      setPos(false);
    };
    document.addEventListener("mouseup", mouseUp);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
    };
  }, [pos]);

  useEffect(() => {
    const zoomEvent = ({ value }) => {
      if (!value) return { value: zoom };
      setZoom(value);
    };

    view.on("zoomSelect", 10, zoomEvent);
    return () => {
      view.off("zoomSelect", zoomEvent);
    };
  }, [view, zoom]);

  useEffect(() => {
    const point = pointRef.current;
    const size = point.getBoundingClientRect();

    const scrollMover = (type) => {
      let moving = false;

      const types = {
        left: {
          move: () => (point.scrollLeft -= 10),
          check: ({ x }) => x < size.left + point.scrollLeft + 40,
        },
        right: {
          move: () => (point.scrollLeft += 10),
          check: ({ x }) => x > size.right + point.scrollLeft - 40,
        },
        top: {
          move: () => (point.scrollTop -= 10),
          check: ({ y }) => y < size.top + 50,
        },
        bottom: {
          move: () => (point.scrollTop += 10),
          check: ({ y }) => y > size.bottom - size.top + point.scrollTop - 60,
        },
      };
      const scrollMove = () => {
        if (!moving) return;

        types[type].move();
        setTimeout(scrollMove, 50);
      };
      return (data) => {
        if (data.stop) {
          moving = false;
          return;
        }

        if (types[type].check(data)) {
          if (!moving) {
            moving = true;
            scrollMove();
          }
        } else moving = false;
      };
    };
    const moves = ["left", "right", "top", "bottom"].map((type) =>
      scrollMover(type)
    );

    const relMouseMove = ({ x, y, stop, noMove }) => {
      moves.forEach((mover) => !noMove && mover({ x, y, stop }));

      x /= zoom / 100;
      y /= zoom / 100;
      return { x: parseInt(x, 10), y: parseInt(y, 10) };
    };

    view.on("relMouseMove", relMouseMove);
    return () => {
      view.off("relMouseMove", relMouseMove);
    };
  }, [view, zoom]);

  const translate = `-${(100 - zoom) / 2}%`;

  return (
    <InputWrapper model={{ model, className: "mt-0 mb-0" }}>
      <Wrapper
        ref={pointRef}
        className={[model.className, "overflow-scroll", "select-none"]}
      >
        <Wrapper
          style={{
            transform: `translateX(${translate}) translateY(${translate}) scale(${zoom}%)`,
          }}
          onMouseDown={(event) => {
            if (!event.ctrlKey) return;

            const { pageX, pageY } = event;
            setPos({ pageX, pageY });

            return false;
          }}
        >
          <ContentTypeRender items={item} />
        </Wrapper>
      </Wrapper>
    </InputWrapper>
  );
};

export default StylePanAndZoom;
