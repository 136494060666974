import { useEffect } from "react";
import app from "nystem";

const ViewButtonEventClearValue = ({ model, view }) => {
  useEffect(() => {
    const emitterByType = {
      connection: {
        event: (type, data) => app().connection.emit({ type, ...data }),
      },
      view: view,
      baseView: view.baseView,
      baseViewBaseView: view.baseView?.baseView,
      baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
      app: app(),
    };
    const emitter = emitterByType[model.eventType || "view"];

    const setEmpty = () => {
      view.setValue({ value: {} });
    };

    emitter.on(model.event, -100, setEmpty);
    return () => {
      emitter.off(model.event, setEmpty);
    };
  }, [model, view]);

  return null;
};
export default ViewButtonEventClearValue;
