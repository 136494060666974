import { ExportStyle } from "nystem-components";

const MultilinetextExport = ({ model, view, label }) => {
  const { className, labelClass, id, text, separator } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };
  let value = view.getValue(id) || [];
  value = value instanceof Array ? value : [value];

  return {
    ...ExportStyle({ className }),
    value: value.join(separator || " "),
  };
};

export default MultilinetextExport;
