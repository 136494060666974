import React from "react";
import { Wrapper, TextView } from "nystem-components";

const TextShowstatdata = ({ value, model, view }) => {
  if (!(value instanceof Array))
    return <TextView value={value} view={view} model={model} />;

  const { classNameLabel, classNameTable, classNameData } = model;

  const mapHeader = (value, key) => (
    <Wrapper renderAs="th" className={classNameLabel} key={key}>
      {value || ""}
    </Wrapper>
  );
  const mapField = (value, key) => (
    <Wrapper renderAs="td" className={classNameData} key={key}>
      {value || ""}
    </Wrapper>
  );
  const mapRow = (item, key) => <tr key={key}>{item.map(mapField)}</tr>;

  return (
    <table className={classNameTable}>
      <thead>
        <tr>{value[0].map(mapHeader)}</tr>
      </thead>
      <tbody>{value.slice(1).map(mapRow)}</tbody>
    </table>
  );
};
export default TextShowstatdata;
