import React from "react";
import { Wrapper } from "nystem-components";

const FloatWarning = ({ model, value }) => {
  const { className, limit, inverse, text } = model;
  const test = value < limit;

  return value && ((test && !inverse) || (!test && inverse)) ? (
    <Wrapper className={className}>{text}</Wrapper>
  ) : null;
};
export default FloatWarning;
