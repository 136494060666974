import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }

  const secure = model.secure ? "/secure" : "";

  return `/${type}${model.addToPath || ""}${secure}/${add || ""}${
    tokenId || id
  }.${ext}`;
};

const MediaDynamicFields = ({ model, view, path, value }) => {
  value = value instanceof Array ? value[0] : value;
  const { item, className } = model;

  if (!value) return null;

  return (
    <Wrapper
      renderAs="a"
      className={[className]}
      href={`${makeUrl({ model, value, view })}?name=${value.name}`}
      target="_blank"
      title={value.name}
    >
      {<ContentTypeRender path={path} items={item} />}
    </Wrapper>
  );
};

export default MediaDynamicFields;
