/* eslint-disable no-continue */
import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";
import app from "nystem";

const isset = (val) => !!val && !(val instanceof Array && val.length === 0);
const getValue = (data, path) =>
  path ? path.split(".").reduce((val, key) => val && val[key], data) : data;

const ReferenceConditional = ({ model, value }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const { condition, source } = model;

    const testCondition = (data) => {
      for (let i = 0; i < condition.length; i++) {
        const id = condition[i][0];
        const val = getValue(data, id);

        let test = condition[i][1];
        if (test === "false") {
          if (!isset(val)) return true;
          continue;
        }
        if (test === "undefined") {
          if (val === undefined) return true;
          continue;
        }
        if (test === "true") {
          if (isset(val)) return true;
          continue;
        }
        const reverse = test[0] === "!";
        if (reverse) test = test.substring(1);
        test = new RegExp(`^${test}$`, "i");
        if (test.test(val)) {
          if (!reverse) return true;
        } else if (reverse) return true;
      }
      return false;
    };

    app()
      .database[source].get({ id: value })
      .then(({ data }) => {
        setVisible(testCondition(data));
      });
  }, [model, value]);

  if (visible)
    return (
      <Wrapper className={model.className}>
        <ContentTypeRender items={model.item} />
      </Wrapper>
    );
  return null;
};

export default ReferenceConditional;
