import React, { useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const fetchFiles = async (dirHandle) => {
  const out = [];

  for await (const handle of dirHandle.values()) {
    console.log(handle);
    out.push({
      handle,
      kind: handle.kind,
      name: handle.name,
      files: handle.kind === "directory" ? await fetchFiles(handle) : undefined,
    });
  }

  return out;
};

const FolderView = ({ model, path }) => {
  const { className, renderAs, item } = model;

  useEffect(() => {});

  const pick = async () => {
    const dirHandle = await window.showDirectoryPicker();
    console.log(await fetchFiles(dirHandle));
  };

  return (
    <Wrapper onClick={pick} className={className} renderAs={renderAs}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default FolderView;
