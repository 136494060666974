import React, { useState, useEffect } from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const useKeyCode = (codes) => {
  codes = codes.map((keyCode) => parseInt(keyCode, 10));
  const [active, setActive] = useState(false);
  useEffect(() => {
    const keyDown = (event) => {
      if (codes.contains(event.keyCode)) setActive(true);
    };
    const keyUp = (event) => {
      if (codes.contains(event.keyCode)) setActive(false);
    };
    window.addEventListener("keydown", keyDown, false);
    window.addEventListener("keyup", keyUp, false);

    return () => {
      window.removeEventListener("keyup", keyUp);
      window.removeEventListener("keydown", keyDown);
    };
  }, [codes]);

  return active;
};

const ConditionalKeypress = ({ model, path }) => {
  const { className, item, keyCodes, invert } = model;
  const active = useKeyCode(keyCodes);

  return (active && !invert) || (!active && invert) ? null : (
    <Wrapper className={className}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ConditionalKeypress;
