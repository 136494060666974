import {
  Link,
  Wrapper,
  SessionLogout,
  ConnectionView,
  Image,
  Icon,
  SessionRole,
} from "nystem-components";
import React, { useState, useEffect } from "react";
import app from "nystem";

const MenuLink = ({ to, children, className, match }) => (
  <Link
    className={`block rounded-md px-2 hover:bg-orange-100 hover:text-black hover:shadow ${
      className || ""
    }`}
    to={to}
    match={match || to}
  >
    {children}
  </Link>
);
const DropDownLink = ({ children, className, ...props }) => {
  const classNames = `hover:text-black px-2 block rounded-md ${
    className || ""
  }`;

  return (
    <Link {...props} className={classNames}>
      {children}
    </Link>
  );
};

const PartMenu = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = () => {
      if (open === true) setOpen(false);
    };

    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open]);

  return (
    <Wrapper className="fixed top-0 z-20 w-full py-1 pl-3 pr-0 md:mx-0">
      <Wrapper
        renderAs="nav"
        className="m-auto flex max-w-6xl items-center justify-center bg-gray-100 text-base text-gray-800 shadow"
      >
        <Link to="/" exact="true">
          <ConnectionView>
            <Image
              alt="Logo"
              className="text-red ml-2 pr-2"
              width={40}
              height={30}
              src="/image/search-stats.svg"
            />
          </ConnectionView>
          <ConnectionView offline="true">
            <Image
              alt="Logo"
              className="text-red ml-2 pr-2 grayscale"
              width={40}
              height={30}
              src="/image/search-stats.svg"
            />
          </ConnectionView>
        </Link>
        <Wrapper className="flex flex-grow">
          <MenuLink className="py-3" to="/statdata/list" match="/statdata/*">
            Data
          </MenuLink>
          <MenuLink className="py-3" to="/class/list" match="/class/*">
            Class
          </MenuLink>
          <MenuLink className="py-3" to="/census">
            Census
          </MenuLink>
          <MenuLink className="py-3" to="/parish">
            Parish
          </MenuLink>
          <MenuLink className="py-3" to="/page/help">
            Help
          </MenuLink>
        </Wrapper>
        <Icon
          icon="bars"
          className="mr-2 h-8 w-8 cursor-pointer"
          onClick={() => setOpen(!open)}
        />
        <Wrapper className="relative w-1">
          {open ? (
            <Wrapper className="absolute right-0 mt-6 w-32 border border-gray-300 bg-gray-100 text-base text-gray-800 shadow-xl">
              <MenuLink
                className="py-2 pt-3 text-center"
                to="/statdata/list"
                match="/statdata/*"
              >
                Data
              </MenuLink>
              <MenuLink className="py-2 pt-3 text-center" to="/census">
                Census
              </MenuLink>
              <MenuLink className="py-2 pt-3 text-center" to="/parish">
                Parish
              </MenuLink>
              <MenuLink className="py-2 pt-3 text-center" to="/page/help">
                Help
              </MenuLink>
              <SessionLogout
                type="button"
                className="block cursor-pointer py-2 px-2 text-center  hover:bg-orange-100 hover:shadow"
              >
                {app().t("Log out")}
              </SessionLogout>
              <SessionRole userrole="config super">
                <DropDownLink
                  to="/admin"
                  className="py-2 text-center hover:bg-orange-100 hover:shadow"
                >
                  Admin
                </DropDownLink>
              </SessionRole>
            </Wrapper>
          ) : null}
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};
export default PartMenu;
