import { useState, useEffect } from "react";
import app from "nystem";

const useSearch = ({ contentType, sortby, count, view } = {}) => {
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (!contentType) return;

    if (!view.value.namelast || !view.value.namefrst || !view.value.bdate) {
      setResult([]);
      return;
    }
    const db = app().database[contentType];
    db.search({
      filter: {
        $and: [
          { namelast: view.value.namelast },
          { namefrst: view.value.namefrst },
          { _crdate: `${view.value.bdate}` },
        ],
      },
      count: count || 100,
      data: undefined,
      statFields: [{ id: "namelast" }, { id: "namefrst" }],
      sortby,
    }).then(({ data }) => setResult(data || []));
  }, [
    setResult,
    contentType,
    sortby,
    count,
    view.value.namelast,
    view.value.namefrst,
    view.value.bdate,
  ]);

  return { result };
};

const ReferenceCensus = ({ model, view, value = [], setValue }) => {
  const { contentType, sortby, searchCount, jwfield } = model;
  const { result } = useSearch({
    contentType,
    sortby,
    count: searchCount,
    view,
  });

  value = value instanceof Array ? value : [value];

  useEffect(() => {
    if (JSON.stringify(value) === JSON.stringify(result)) return;

    const timer = setTimeout(() => {
      setValue(result);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [result, setValue, value]);

  useEffect(() => {
    if (!jwfield) return;

    if (value.length === 0 && view.value[jwfield]) {
      view.setValue({ path: jwfield, value: undefined });
      return;
    }

    let timer = 0;
    const setDiff = ({ value }) => {
      if (view.value[jwfield] !== value) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          view.setValue({ path: jwfield, value });
        }, 100);
      }
    };
    app().on(`jaroWrinkler-${value[0]}`, setDiff);
    return () => {
      clearTimeout(timer);
      app().off(`jaroWrinkler-${value[0]}`, setDiff);
    };
  }, [jwfield, value, view]);

  return null;
};
export default ReferenceCensus;
