import { ExportStyle } from "nystem-components";
import moment from "my-moment";

const DateExport = ({ model, view, label }) => {
  const { className, labelClass, id, text, dateFormat, offset } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };
  let value = view.getValue(id) || "";
  if (model.missingField && view.getValue(model.missingField))
    return { ...ExportStyle({ className }), value: -1 };

  if (value) {
    value = moment(value).format(dateFormat);
    if (offset) value = parseInt(value, 10) - offset;
    if (model.asInt) value = parseInt(value, 10);
  } else value = "";
  return { ...ExportStyle({ className }), value };
};

export default DateExport;
