import React, { useEffect, useState } from "react";
import { Button, Wrapper, UseValidator, InputWrapper } from "nystem-components";
import app from "nystem";

const validate = ({ value, model }) =>
  model.mandatory && !value && (model.text_mandatory || "Field is mandatory");

const ElectronSelectFolder = ({ model, view }) => {
  const [value, setValue] = useState([]);

  const [error] = UseValidator({ view, validate, value, model });
  const [id] = useState(app().uuid);

  useEffect(() => {
    const electronFolder = () => {
      return { path: value };
    };
    view.on("electronFolder", electronFolder);

    return () => {
      view.off("electronFolder", electronFolder);
    };
  }, [model, value, view]);

  return (
    <InputWrapper id={id} model={model} error={error}>
      <Button
        size="xs"
        className={model.buttonClassName}
        onClick={async () => {
          const { filePaths } = await app().event("electronEvent", {
            event: "dialog",
          });
          setValue(filePaths[0]);
        }}
      >
        {model.text}
      </Button>
      <Wrapper className={model.textClassName}>{value}</Wrapper>
    </InputWrapper>
  );
};

export default ElectronSelectFolder;
