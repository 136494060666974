import {
  Inserter,
  Wrapper,
  SessionRole,
  PartMenu,
  PartMenuv2,
  DragAndDropContext,
  TinymcePortal,
  PartAdmin,
  PartAdminv2,
  ElectronView,
  ElectronAppButton,
  RouterRedirect,
  StyleSizeMirror,
  Link,
  Icon,
} from "nystem-components";
import React, { useState } from "react";
import app from "nystem";
import "../../../index.css";
import "./index.css";

const v1 = () => (
  <>
    <PartMenu />
    <Wrapper className="mt-12">
      <PartAdmin />
      <Inserter match="/census" source="/census/list" />
      <Inserter match="/" source="/page/view/first" />
      <Inserter match="/page/*" source="/page/view/*" />
      <Inserter
        match="/page/help"
        source="/page/debugandclear"
        className="m-auto max-w-6xl p-3 "
      />
      <Inserter match="/parish" source="/parish/list" />
      <Inserter match="/statdata/*" />
      <Inserter className="m-auto max-w-6xl p-3 " match="/class/*" />
    </Wrapper>
  </>
);

const v2 = () => (
  <>
    <PartMenuv2 />
    <Wrapper className="mt-12">
      <Inserter
        className="m-auto max-w-6xl p-3"
        match="/*"
        source="/page/view/*"
      />
      <PartAdminv2 />
      <Inserter
        className="m-auto max-w-6xl p-3"
        match="/upload"
        source="/upload/list"
      />
      <Inserter className="m-auto max-w-6xl p-3" match="/upload/*" />
      <SessionRole userrole="config super">
        <Inserter match="/">
          <Wrapper className="m-auto max-w-6xl bg-green-100 p-3 font-bold">
            As shown for logged out
          </Wrapper>
        </Inserter>
      </SessionRole>
      <SessionRole userrole="config super">
        <Inserter
          className="m-auto max-w-6xl px-3"
          match="/"
          source="/page/view/loggedout"
        />
      </SessionRole>
      <SessionRole userrole="config super">
        <Inserter match="/">
          <Wrapper className="m-auto max-w-6xl bg-green-100 p-3 font-bold">
            As shown for archive
          </Wrapper>
        </Inserter>
      </SessionRole>
      <SessionRole userrole="usertype2 config super">
        <Inserter
          className="m-auto max-w-6xl px-3"
          match="/"
          source="/page/view/archive"
        />
      </SessionRole>
      <SessionRole userrole="config super">
        <Inserter match="/">
          <Wrapper className="m-auto max-w-6xl bg-green-100 p-3 font-bold">
            As shown for assistant
          </Wrapper>
        </Inserter>
      </SessionRole>
      <SessionRole userrole="user config super">
        <Inserter
          className="m-auto max-w-6xl px-3"
          match="/"
          source="/page/view/first"
        />
      </SessionRole>
      <Inserter
        match="/help"
        source="/page/debugandclear"
        className="m-auto max-w-6xl p-3 "
      />
      <Inserter match="/parish" source="/parish/list" />
      <Inserter match="/statdata/*" />
      <Inserter className="m-auto max-w-6xl p-3 " match="/trainingdata/*" />
      <Inserter className="m-auto max-w-6xl p-3 " match="/trainingresult/*" />
      <Inserter className="m-auto max-w-6xl p-3 " match="/training/navigate*" />
      <Inserter
        className="m-auto max-w-6xl p-3 "
        match="/training/train/*"
        source="/upload/train/*"
      />
      <Inserter match="/census" source="/census/list" />
      <Inserter className="m-auto max-w-6xl p-3 " match="/class/*" />
      <Inserter match="/login">
        <RouterRedirect to="/" />
      </Inserter>
    </Wrapper>
  </>
);

const Dropdown = () => {
  const [open, setOpen] = useState(false);

  if (!open)
    return (
      <Wrapper
        onClick={() => setOpen(!open)}
        className="fixed top-0 z-50 m-1 cursor-pointer rounded-full bg-white opacity-75 md:hidden"
      >
        <Wrapper>
          <Icon icon="bars" className="m-1 h-8 w-8 p-1" />
        </Wrapper>
      </Wrapper>
    );

  return (
    <Wrapper
      className="fixed top-0 z-50 w-64 bg-white p-1 shadow-2xl md:hidden"
      onClick={() => setOpen(!open)}
    >
      <Wrapper>
        <Icon icon="xmark" className="m-1 h-8 w-8 cursor-pointer p-1" />
      </Wrapper>
      <Inserter
        className="max-h-95"
        match="*"
        source="/menu/headerlist/loggedout"
      />
    </Wrapper>
  );
};

const Index = () => (
  <DragAndDropContext>
    <TinymcePortal />
    <SessionRole userrole="logged-out">
      <ElectronView>
        <ElectronAppButton
          className="float-right h-8 w-8 cursor-pointer rounded px-2 hover:bg-gray-100"
          button="close"
        />
      </ElectronView>
      <Wrapper className="z-5 sticky top-0 left-0 right-0 flex">
        <Dropdown />
        <Wrapper className="min-w-60 mr-3 hidden min-w-max grow justify-end md:flex">
          <StyleSizeMirror mirrorId="list" setWidth="true"></StyleSizeMirror>
        </Wrapper>
        <Wrapper className="grow-2 relative max-w-2xl bg-white">
          <Inserter
            className="-mt-3"
            match="*"
            source="/page/view/loggedoutheader"
          />
        </Wrapper>
        <Wrapper className="hidden min-w-max grow justify-end md:flex">
          <StyleSizeMirror mirrorId="login">
            <Link to="/login" className="block py-2 px-3">
              Log in
            </Link>
          </StyleSizeMirror>
        </Wrapper>
      </Wrapper>
      <Wrapper className="flex">
        <Wrapper className="min-w-60 z-10 mr-3 hidden min-w-max grow justify-end md:flex">
          <StyleSizeMirror mirrorId="list" setWidth="true"></StyleSizeMirror>
          <StyleSizeMirror mirrorId="list" className="fixed">
            <Inserter
              className="z-100 relative -top-28"
              match="*"
              source="/menu/headerlist/loggedout"
            />
          </StyleSizeMirror>
        </Wrapper>
        <Wrapper className="grow-2 -mt-1 max-w-2xl px-6">
          <Inserter match="/" source="/page/view/loggedout" />
          <Inserter match="/*" source="/page/view/*" />
          <Inserter
            className="m-auto max-w-6xl p-3"
            match="/login"
            source="/user/login"
          />
        </Wrapper>
        <Wrapper className="hidden min-w-max grow justify-end md:flex">
          <StyleSizeMirror mirrorId="login" setWidth="true"></StyleSizeMirror>
        </Wrapper>
      </Wrapper>
    </SessionRole>
    <SessionRole userrole="logged-in">
      {app().settings.v2 ? v2() : v1()}
    </SessionRole>
  </DragAndDropContext>
);

export default Index;
