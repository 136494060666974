import { useEffect, useRef } from "react";
import app from "nystem";

const SelectCorrectionsEdit = ({ model, view, value }) => {
  const { imageId } = model;
  value = value || [];
  value = value instanceof Array ? value : [value];

  const ref = useRef(value);

  useEffect(() => {
    if (!view.value._id) return;

    const before = ref.current || [];
    ref.current = value;

    const byType = {
      firstImageLast: (undo) => {
        const before = view.getValue(imageId) || [];
        if (!undo) {
          const last = before.pop();
          view.setValue({ path: imageId, value: [last, ...before] });
        } else {
          const first = before.shift();
          view.setValue({ path: imageId, value: [...before, first] });
        }
      },
      uprighter: async (undo) => {
        const before = view.getValue(imageId) || [];

        if (!undo) {
          view.setValue({
            path: imageId,
            value: await Promise.all(
              before.map(async (item, index) => {
                console.log({
                  orientation: index % 2 === 0 ? 8 : 6,
                  url: `/image/${view.contentType}/${item.id}.jpeg`,
                });
                const { img, width, height } = await app().event(
                  "imageModRotate",
                  {
                    orientation: index % 2 === 0 ? 8 : 6,
                    url: `/image/${view.contentType}/${item.id}.jpeg`,
                  }
                );

                const newId = app().uuid();

                await app().event("fileUpload", {
                  ...item,
                  contentType: view.contentType,
                  params: [view.contentType],
                  id: newId,
                  pos: index,
                  data: await img.arrayBuffer(),
                });

                return { ...item, id: newId, width, height };
              })
            ),
          });
        } else {
          const first = before.shift();
          view.setValue({ path: imageId, value: [...before, first] });
        }
      },
    };

    before
      .filter((id) => !value.includes(id))
      .forEach((id) => byType[id] && byType[id](true));

    value
      .filter((id) => !before.includes(id))
      .forEach((id) => byType[id] && byType[id](false));
  }, [imageId, value, view]);

  useEffect(() => {
    const before = ref.current;

    const fixCorrections = async (query) => {
      console.log(ref.current, before);
    };
    view.on("save", fixCorrections);
    return () => {
      view.off("save", fixCorrections);
    };
  }, [view]);

  return null;
};
export default SelectCorrectionsEdit;
/*
contentType: "upload"
count: 10
data: ArrayBuffer(2996161)
ext: "jpeg"
file: undefined
id: "e66aa5dc3428e1102a474742657900a3"
mediaId: "db7de167f129f17963d3d809bbcb5fb4"
modelId: "images"
name: "Araslövs skola, klass 1-2, 1918-1919.pdf.9.jpeg"
params: ['upload']
pos: 9
type: "image"
*/
