import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const ConditionalFieldsSet = ({ model, path, value }) => {
  const { className, item, fieldsSet, invert, onUndefined } = model;

  const fieldsIsset = fieldsSet.find((field) =>
    onUndefined ? typeof value[field] === "undefined" : !value[field]
  );

  return (fieldsIsset && !invert) || (!fieldsIsset && invert) ? null : (
    <Wrapper className={className}>
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default ConditionalFieldsSet;
