import React from "react";
import { SelectInput, UseSearch, RouterUseQueryStore } from "nystem-components";
import app from "nystem";

const ReferenceIssetButton = ({ model, view, path }) => {
  const [value, setValue] = RouterUseQueryStore(model.saveId);
  UseSearch({ view, id: model.id, value, exact: model.exact });

  const insertVal = (val) =>
    val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
      let val = "";
      if (p1 === "_language") val = app().settings.lang;
      else if (p1 === "id") val = view.id;
      else if (p1.indexOf("params.") === 0)
        val = view.params[p1.replace("params.", "")];
      else if (p1.indexOf("baseView.") !== 0)
        val = view.getValue(p1.replace("..", path));
      else {
        p1 = p1.replace("baseView.", "");
        val = view.baseView.getValue(p1.replace("..", path));
      }
      if (val instanceof Array) val = val.join("|");
      return val || "";
    });

  const val = insertVal(model.fetch || "");

  return (
    <SelectInput
      model={{
        option: [
          { _id: val, text: model.excludeText },
          { _id: `!${val}`, text: model.includeText },
        ],
        clearButton: true,
        selectAllOnFocus: true,
        limit: 1,
        ...model,
      }}
      value={value}
      setValue={setValue}
    />
  );
};

export default ReferenceIssetButton;
