import React from "react";
import app from "nystem";

class SelectSetStateSwipe extends React.Component {
  componentDidMount() {
    app().on("pointer", this.handleChange);
  }
  componentWillUnmount() {
    app().off("pointer", this.handleChange);
  }
  handleChange = event => {
    const { setValue, model, value } = this.props;
    if (value === model.setState) return;

    const delta = model.vertical ? event.dy : event.dx;

    if (model.limit > 0 ? delta - model.limit > 0 : delta - model.limit < 0)
      setValue(model.id, model.setState);
  };
  render = () => null;
}
export default SelectSetStateSwipe;
