import { ExportStyle } from "nystem-components";
import app from "nystem";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }
  const secure = model.secure ? "/secure" : "";
  return `/${type}${model.addToPath || ""}${secure}/${add || ""}${
    tokenId || id
  }.${ext}`;
};

const MediaExport = ({ model, view, label }) => {
  const { className, labelClass, id, text } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };

  let value = view.getValue(id);
  value = !value || value instanceof Array ? value : [value];

  const attach =
    value &&
    value.map((value) => value && makeUrl({ model, value, add: "", view }));

  return {
    ...ExportStyle({ className }),
    value: value ? (model.limit === 1 ? attach[0] : attach) : value,
    attach: value && attach,
  };
};

export default MediaExport;
