import { useEffect, useRef } from "react";
import app from "nystem";
import { RouterUseQueryStore } from "nystem-components";

const ViewButtonFieldToQueryParam = ({ model, view, path }) => {
  const [value, setValue] = RouterUseQueryStore(model.field, "", model.push);
  const ref = useRef();
  ref.current = setValue;

  useEffect(() => {
    const insertVal = (val) =>
      val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
        let val = "";
        if (p1 === "_language") val = app().settings.lang;
        else if (p1 === "id") val = view.id;
        else if (p1.indexOf("params.") === 0)
          val = view.params[p1.replace("params.", "")];
        else if (p1.indexOf("baseView.") !== 0)
          val = view.getValue(p1.replace("..", path));
        else {
          p1 = p1.replace("baseView.", "");
          val = view.baseView.getValue(p1.replace("..", path));
        }
        if (val instanceof Array) val = val.join("|");
        return val || "";
      });

    const newVal = insertVal(model.fetch);
    if (value !== newVal) ref.current(newVal);
  }, [model.fetch, path, value, view]);

  return null;
};
export default ViewButtonFieldToQueryParam;
