import React, { useEffect, useRef } from "react";
import { Wrapper } from "nystem-components";

const imageLoader = (src) =>
  new Promise((resolve) => {
    const image = new window.Image();
    image.src = src;
    image.onload = () => resolve(image);
    image.onerror = () => resolve("error");
  });

const AreaPreview = ({ model, view, value = {} }) => {
  const ref = useRef();

  const area = {
    x1: value.x1 < value.x2 ? value.x1 : value.x2,
    x2: value.x1 < value.x2 ? value.x2 : value.x1,
    y1: value.y1 < value.y2 ? value.y1 : value.y2,
    y2: value.y1 < value.y2 ? value.y2 : value.y1,
  };

  useEffect(() => {
    const run = async () => {
      const id = view.getValue(model.field);
      const image = await imageLoader(`/image/upload/${id}.jpeg`);

      if (!ref.current) return;
      const mask = ref.current;
      mask.width = area.x2 - area.x1;
      mask.height = area.y2 - area.y1;

      const ctx = mask.getContext("2d");
      ctx.drawImage(image, -area.x1, -area.y1);
    };

    run();
  }, [area, model.field, view]);

  return <Wrapper renderAs="canvas" ref={ref} className={model.className} />;
};
export default AreaPreview;
