import { useEffect, useRef } from "react";
import app from "nystem";

const ReferenceFetch2Field = ({ model, setValue, view, path }) => {
  const atRef = useRef();
  atRef.current = setValue;

  useEffect(() => {
    const insertVal = (val) =>
      val.replace(/\{([a-z_.0-9]+)\}/gim, (str, p1) => {
        let val = "";
        if (p1 === "_language") val = app().settings.lang;
        else if (p1 === "id") val = view.id;
        else if (p1.indexOf("params.") === 0)
          val = view.params[p1.replace("params.", "")];
        else if (p1.indexOf("baseView.") !== 0)
          val = view.getValue(p1.replace("..", path));
        else {
          p1 = p1.replace("baseView.", "");
          val = view.baseView.getValue(p1.replace("..", path));
        }
        console.log("p1", p1, val);
        if (val instanceof Array) val = val.join("|");
        return val;
      });

    setTimeout(() => {
      atRef.current(insertVal(model.insert));
    }, 0);
  }, [model.insert, path, view]);

  return null;
};

export default ReferenceFetch2Field;
