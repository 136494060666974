export default (app) => {
  const dbWorker = new Worker("/localdbworker.js");

  const callbacks = {};

  const receive = async ({ id, prio, off, on, event, data, callback }) => {
    let ev = app;
    if (event) {
      const path = event.split(".");
      [event] = path.splice(path.length - 1, 1);

      ev = path ? path.reduce((prev, curr) => prev[curr], app) : app;
    }

    if (callback) {
      callbacks[callback](data);
      delete callbacks[callback];
      return;
    }

    if (id) {
      dbWorker.postMessage({ id, event, data: await ev.event(event, data) });
      return;
    }

    if (on) {
      const callback = (data) =>
        new Promise((resolve) => {
          const callback = app.uuid();
          dbWorker.postMessage({ on, event, data, callback });
          callbacks[callback] = resolve;
        });

      ev.on(event, callback, prio);
      callbacks[on] = callback;
    }

    if (off) {
      ev.off(event, callbacks[off]);
      delete callbacks[off];
    }
  };

  dbWorker.onmessage = (e) => receive(e.data);
};
