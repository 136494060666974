import { ExportStyle } from "nystem-components";
import app from "nystem";

const getDistance = ([lat1, lon1], [lat2, lon2], unit = "K") => {
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;

  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  if (unit === "K") dist *= 1.609344;
  if (unit === "N") dist *= 0.8684;

  return dist;
};

const GpsExportDistance = async ({ model, view, label }) => {
  const { className, labelClass, id, text, gpsField } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };

  const firstId = view.baseView.getValue(model.baseSource);
  const secondId = view.getValue(id);
  if (!firstId || !secondId)
    return { ...ExportStyle({ className }), value: "" };

  const { data: first } = await app().database[model.source].get({
    id: firstId,
  });
  const { data: second } = await app().database[model.source].get({
    id: secondId,
  });

  const dist =
    first && second ? getDistance(first[gpsField], second[gpsField]) : "";

  return { ...ExportStyle({ className }), value: dist && dist.toFixed(0) };
};

export default GpsExportDistance;
