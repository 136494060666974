import React from "react";
import { Wrapper } from "nystem-components";
import app from "nystem";

const ViewLinkSite = ({ model }) => (
  <Wrapper
    className={model.className}
    href={
      (app().settings.secure ? "https://" : "http://") + app().settings.domain
    }
    target="_blank"
    renderAs="a"
    rel="noopener"
  >
    {model.text}
  </Wrapper>
);

export default ViewLinkSite;
