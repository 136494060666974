import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const getStyle = ({ x1, y1, x2, y2 }) => ({
  left: `${Math.abs(x1)}px`,
  top: `${Math.abs(y1)}px`,
  width: `${x2 - x1}px`,
  height: `${y2 - y1}px`,
});

const AreaView = ({ model, value }) => {
  if (!value) return null;

  const area = {
    x1: value.x1 < value.x2 ? value.x1 : value.x2,
    x2: value.x1 < value.x2 ? value.x2 : value.x1,
    y1: value.y1 < value.y2 ? value.y1 : value.y2,
    y2: value.y1 < value.y2 ? value.y2 : value.y1,
  };

  return (
    <Wrapper className={model.className} style={getStyle(area)}>
      {model.item && <ContentTypeRender items={model.item} />}
    </Wrapper>
  );
};
export default AreaView;
