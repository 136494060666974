import React from "react";
import { TextInput } from "nystem-components";

const TextLowercase = ({ model, value, setValue }) => (
  <TextInput
    model={{ ...model }}
    value={value}
    setValue={(value) =>
      model.toLowerCase ? setValue(value.toLowerCase()) : setValue(value)
    }
  />
);

export default TextLowercase;
