import React, { useState, useEffect } from "react";
import app from "nystem";
import { ContentTypeRender, Wrapper } from "nystem-components";

const LocaldbView = ({ model, path }) => {
  const { className, item, contentType } = model;

  const [progress, setProgress] = useState(-1);
  useEffect(() => {
    const load = async ({ searchTotal }) => {
      if (searchTotal) {
        setProgress(100);
        return;
      }

      let timer = false;
      const loadDb = async () => {
        const { state } = await app().event("localdbworker", {
          ...app().contentType[contentType],
          load: true,
        });
        if (state === undefined) timer = setTimeout(loadDb, 50);
      };
      loadDb();

      return () => {
        if (timer) clearTimeout(timer);
      };
    };
    app().database[contentType].search({ limit: 1 }).then(load);

    const progress = ({ procent }) => setProgress(procent);

    app().on(`localdbworker-${contentType}`, progress);
    return () => {
      app().off(`localdbworker-${contentType}`, progress);
    };
  }, [contentType]);

  if (progress === -1) return null;

  return (
    <Wrapper className={className}>
      {progress === 100 ? (
        <ContentTypeRender path={path} items={item} />
      ) : (
        <Wrapper className="shadow w-full bg-gray-100 max-w-xl">
          <Wrapper
            className="loading bg-blue text-xs leading-none py-1 text-center text-black"
            style={{ width: `${progress}%` }}
          >
            {model.loadingtext}
          </Wrapper>
        </Wrapper>
      )}
    </Wrapper>
  );
};
export default LocaldbView;
