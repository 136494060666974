import { ExportStyle } from "nystem-components";

const SelectExport = ({ model, view, label }) => {
  const { className, labelClass, id, text } = model;

  if (label) return { ...ExportStyle({ className: labelClass }), value: text };
  return { ...ExportStyle({ className }), value: view.getValue(id) };
};

export default SelectExport;
