import React, { useEffect, useRef, useState } from "react";
import {
  MediaView,
  InputWrapper,
  Wrapper,
  ContentTypeView,
} from "nystem-components";

const useCtrl = () => {
  const [ctrl, setCtrl] = useState(false);
  useEffect(() => {
    const keyDown = (event) => {
      if (event.keyCode === 17) setCtrl(true);
    };
    const keyUp = (event) => {
      if (event.keyCode === 17) setCtrl(false);
    };
    window.addEventListener("keydown", keyDown, false);
    window.addEventListener("keyup", keyUp, false);

    return () => {
      window.removeEventListener("keyup", keyUp);
      window.removeEventListener("keydown", keyDown);
    };
  }, []);

  return ctrl;
};

const relPos = ({ pointRef, event, view, stop, noMove }) => {
  const { x, y } = pointRef.current.getBoundingClientRect();
  const { pageX, pageY } = event;

  return view.event("relMouseMove", {
    x: parseInt(pageX - x, 10),
    y: parseInt(pageY - y, 10),
    stop,
    noMove,
  });
};

const FollowBox = ({ model, add2Result, pointRef, size, view }) => {
  const [pos, setPos] = useState(false);

  useEffect(() => {
    const mouseMove = async (event) => {
      if (!pointRef.current) return;
      setPos(await relPos({ pointRef, event, view, noMove: true }));
    };

    document.addEventListener("mousemove", mouseMove);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  }, [pointRef, view]);

  if (!pos) return null;
  return (
    <Wrapper
      className="absolute"
      style={{
        left: `${pos.x}px`,
        top: `${pos.y}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
      }}
    >
      <ContentTypeView
        key={`${size.width} ${size.height}`}
        contentType={model.contentType}
        format={model.inputView}
        value={{
          ...add2Result,
          area: { x1: 0, y1: 0, x2: size.width, y2: size.height },
        }}
        baseView={view}
        params={view.params}
      />
    </Wrapper>
  );
};

const AreaInput = ({
  model,
  view,
  add2Result,
  setValue,
  refImage,
  pointRef,
}) => {
  const ctrl = useCtrl();
  const [pos, setPos] = useState(false);
  const [toPos, setToPos] = useState(false);
  const [storedSize, setStoredSize] = useState(false);

  useEffect(() => {
    const onMouseDown = async ({ event }) => {
      if (ctrl) return;
      if (!add2Result) return;

      const { x: x1, y: y1 } = await relPos({ pointRef, event, view });

      setPos({ x1, y1 });
      setToPos({ x2: x1, y2: y1 });
      return false;
    };

    view.on("areaInputRef", onMouseDown);
    return () => {
      view.off("areaInputRef", onMouseDown);
    };
  }, [add2Result, ctrl, pointRef, view]);

  useEffect(() => {
    if (!pos) return;
    let toPos = {};

    const mouseMove = async (event) => {
      const { x: x2, y: y2 } = await relPos({ pointRef, event, view });

      toPos = { x2, y2 };
      setToPos(toPos);
    };

    document.addEventListener("mousemove", mouseMove);

    const mouseUp = async (event) => {
      setPos(false);
      const { x: x2, y: y2 } = await relPos({
        pointRef,
        event,
        view,
        stop: true,
      });

      const area = { ...pos, x2, y2 };

      const width = area.x2 - area.x1;
      const height = area.y2 - area.y1;

      if (Math.abs(width) < 2 && Math.abs(height) < 2) {
        if (!storedSize.width) return;

        area.x2 = area.x1 + storedSize.width;
        area.y2 = area.y1 + storedSize.height;
      } else setStoredSize({ width, height });

      view.event("addResult", {
        value: {
          ...add2Result,
          upload: view.id,
          refImage,
          area,
        },
      });
    };
    document.addEventListener("mouseup", mouseUp);

    return () => {
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
    };
  }, [
    model.contentType,
    pos,
    setValue,
    storedSize,
    view,
    refImage,
    add2Result,
    pointRef,
  ]);

  return (
    <>
      {!pos && !ctrl && storedSize && (
        <FollowBox
          model={model}
          add2Result={add2Result}
          pointRef={pointRef}
          size={storedSize}
          view={view}
        />
      )}
      {pos && (
        <ContentTypeView
          contentType={model.contentType}
          format={model.inputView}
          value={{
            ...add2Result,
            area: { ...pos, ...toPos },
          }}
          baseView={view}
          params={view.params}
        />
      )}
    </>
  );
};

const MediaAreaInput = ({ model, view, value, path }) => {
  const pointRef = useRef();
  const [at, setAt] = useState(0);
  const [add2Result, setAdd2Result] = useState(false);

  value = value || [];

  useEffect(() => {
    const add2Result = ({ value }) => {
      setAdd2Result(value);
    };

    view.on("add2Result", add2Result);
    return () => {
      view.off("add2Result", add2Result);
    };
  }, [view]);

  useEffect(() => {
    const atEvent = ({ at }) => {
      setAt(at);
    };

    view.on("atImageEvent", atEvent);

    return () => {
      view.off("atImageEvent", atEvent);
    };
  }, [view]);

  return (
    <InputWrapper model={model}>
      <Wrapper className="relative table">
        <MediaView
          key={value[at]?.id}
          model={{
            ...model,
            className: "",
            itemClassName: "max-w-none",
            fullSize: true,
          }}
          value={value[at]}
          view={view}
          path={path}
        />
        <Wrapper
          ref={pointRef}
          className="absolute top-0 right-0 left-0 bottom-0"
          onMouseDown={(event) => {
            view.event("areaInputRef", { event });
          }}
        >
          <AreaInput
            pointRef={pointRef}
            refImage={value[at].id}
            view={view}
            add2Result={add2Result}
            model={model}
          />
          <ContentTypeView
            key={value[at]?.id}
            contentType={model.contentType}
            format={model.view}
            baseView={view}
            value={{
              ...add2Result,
              imageId: (value[at] && value[at].id) || "",
            }}
            params={view.params}
          />
        </Wrapper>
      </Wrapper>
    </InputWrapper>
  );
};

export default MediaAreaInput;
