import React from "react";
import { Wrapper, Image, Audio, Icon } from "nystem-components";
import app from "nystem";

const makeUrl = ({ model, value, add, view }) => {
  const { id, ext, type } = value;
  let tokenId = false;
  if (model.secure) {
    tokenId = app().uuid();
    const { contentType } = view;
    app().connection.emit({
      type: "secureFileToken",
      tokenId: `${tokenId}.${ext}`,
      fileId: `${id}.${ext}`,
      ext,
      contentType,
      modelId: model.id,
    });
  }
  const secure = model.secure ? "/secure" : "";
  add = (model.add2Path || "") + (add || "");

  return `/${type}${model.addToPath || ""}${secure}/${add}${
    tokenId || id
  }.${ext}`;
};

const AudioItem = ({ value, model, view, setValue, key }) => (
  <Audio
    key={key}
    preload={model.preload}
    src={makeUrl({ model, value, view })}
    type="audio/mpeg"
    playing={value.playing}
    currentTime={value.currentTime}
    updateInterval={model.updateinterval}
    onChange={(settings) => {
      setValue({ ...value, ...settings });
    }}
  />
);

const logInfo = async (url) => {
  const res = await fetch(url);
  const data = await res.json();
  console.log(data);
  console.log(data.text);
};

const ImageItem = ({ value, model, view, key }) => {
  const { width, height } = app().image.calculateSize(value, model);
  const add =
    value.ext === "svg" || model.fullSize ? "" : `${width}x${height}/`;
  const style = model.fullSize
    ? { width: `${value.width}px`, height: `${value.height}px` }
    : {};

  const image = (
    <Image
      key={key}
      width={width}
      height={height}
      fixed={model.fixed}
      src={makeUrl({ model, value, add, view })}
      className={!model.slideshowOnClick && model.itemClassName}
      alt={value.name}
      limit={model.fullImageLimit}
      style={style}
      draggable={!!model.draggable}
    />
  );
  return model.slideshowOnClick ? (
    <Wrapper className={model.itemClassName} key={key}>
      <Wrapper
        renderAs="a"
        className={value.orientation ? "absolute" : "hidden"}
        href={`/ocrdata/${value.rotatedFrom || value.id}.json`}
        target="_blank"
      >
        <Icon
          onClick={() =>
            logInfo(`/ocrdata/${value.rotatedFrom || value.id}.json`)
          }
          icon="gear"
          className="h-5 w-5 p-1 text-gray-500"
          title="Ocr data"
        />
      </Wrapper>
      <Wrapper onClick={() => view.event("slideShow", { model, index: key })}>
        {image}
      </Wrapper>
    </Wrapper>
  ) : (
    image
  );
};

const AttachmentItem = ({ value, model, view, key }) => (
  <Wrapper
    key={key}
    renderAs="a"
    className={[model.itemClassName, "p-3"]}
    href={`${makeUrl({ model, value, view })}?name=${value.name}`}
    target="_blank"
  >
    {value.name || model.text}
  </Wrapper>
);

const componentByType = {
  audio: AudioItem,
  image: ImageItem,
  attachment: AttachmentItem,
};

const MediaViewOcrLink = ({ model, view, setValue, value }) => {
  value = value instanceof Array ? value : [value];
  if (!value[0]) value = [];

  return (
    <Wrapper className={model.className}>
      {value.map((value, index) => {
        const type = ["audio", "image"].includes(value.type)
          ? value.type
          : "attachment";
        return componentByType[type]({
          key: index,
          value,
          model,
          view,
          setValue,
        });
      })}
    </Wrapper>
  );
};

export default MediaViewOcrLink;
