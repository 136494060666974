import React from "react";
import { Wrapper, ContentTypeRender } from "nystem-components";

const GpsLink = ({ model, value, path }) => {
  const { className, item } = model;
  if (!value) return null;
  const pos = value.join(",");
  return (
    <Wrapper
      className={className}
      renderAs="a"
      href={`https://www.google.com/maps/search/?q=${pos}&ll=${pos}&z=9`}
      target="_blank"
    >
      <ContentTypeRender path={path} items={item} />
    </Wrapper>
  );
};
export default GpsLink;
