import { useEffect } from "react";
import app from "nystem";

const pick = (fields, value) =>
  fields
    ? fields.reduce(
        (prev, [id, to]) => (value[id] ? { ...prev, [to]: value[id] } : prev),
        {}
      )
    : value;

const ViewButtonEvent2Value = ({ model, view, setValue }) => {
  useEffect(() => {
    const emitterByType = {
      view: view,
      baseView: view.baseView,
      baseViewBaseView: view.baseView?.baseView,
      baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
      app: app(),
    };
    const emitter = emitterByType[model.eventType || "view"];

    const setValues = async ({ value }) => {
      await setValue(pick(model.fields, value));
    };

    emitter.on(model.event, 100, setValues);
    return () => {
      emitter.off(model.event, setValues);
    };
  }, [model, setValue, view]);

  return null;
};
export default ViewButtonEvent2Value;
